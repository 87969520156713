import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-recruiter-router',
    template: `
    <app-candidates></app-candidates>
  `,
    standalone: false
})
export class RecruiterRouterComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}