import { Component, OnDestroy, OnInit } from '@angular/core'

import { Observable } from 'rxjs'
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'

import * as fromCoreStore from '@app/core/store'

import {
    LockedItem,
    FOLLOW_UP_RESOURCE,
    Order,
} from '@app/shared/models'
import { LockedItemWorkerFactory, LockItemWorker } from '@app/core/services/locked-item-worker.factory'
import { SessionEndService } from '@app/shared/services/session-end.service'

@Component({
    selector: 'app-candidates-router',
    template: `
      <div class="app-candidates-router">
        <app-candidate></app-candidate>
      </div>
  `,
    styles: [`
      @tailwind base;
      @tailwind components;
      @tailwind utilities;

      .app-candidates-router {
        @apply bg-gray-100 pb-[20px]
      }

  `],
    standalone: false
})
export class CandidateItemRouterComponent implements OnInit, OnDestroy { 
    
    constructor(
        private store: Store<fromCoreStore.State>,
    ) { }
    ngOnInit(): void {
        
    }
    ngOnDestroy(): void {
        
    }

}