import { Component, OnDestroy, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject, Observable } from 'rxjs'
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms'

import * as fromStore from '@app/core/store'
import * as fromCoreStore from '@app/core/store'
import { OpenModal, CloseModal } from '@app/modals/store/actions/layout.actions'
import moment from 'moment'
import { Candidate, markControlsAsTouched } from '@app/shared'
import { ToastHelper } from '@app/core/services'
import { map, tap, takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-candidate-form',
    templateUrl: './candidate-form.component.html',
    styleUrls: ['./candidate-form.component.scss'],
    standalone: false
})
export class CandidateFormComponent implements OnInit, OnDestroy {
    @Input()
    props

    cdl = [{_id: 1, value: 'Yes'}, {_id: 2, value: 'No'}]
    cdlA = [{_id: 1, value: 'Less than 2 Years'}, {_id: 2, value: '2 Years'}, {_id: 3, value: '3 Years'}, {_id: 4, value: '4 Years'}, {_id: 5, value: '5+ Years'}]
    autoHaulingExp = [{_id: 1, value: 'None'}, {_id: 2, value: '1-5 Months'}, {_id: 3, value: '6-11 Months'}, {_id: 4, value: '12-23 Months'}, {_id: 5, value: '2 Years'}, {_id: 6, value: '3 Years'}, {_id: 7, value: '4 Years'}, {_id: 8, value: '5+ Years'}]

    destroyed$ = new Subject<boolean>();

    candidateForm: FormGroup

    filter$: Observable<any>
    filter: any

    constructor(
        private notify: ToastHelper,
        private coreStore: Store<fromCoreStore.State>,
        private fb: FormBuilder,
    ) { 
        this.candidateForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            phone: ['', Validators.required],
            cdl: ['', Validators.required],
            cdlExp: ['', Validators.required],
            autoHaulingExp: ['', Validators.required],
            dob:[null],
            addressLine: [''],
            city: [''],
            state: [''],
            zip: [''],
            resource: [''],
            otherResource: [''],
            bgInformation: fb.array([
                [{
                    question: 'Criminal record?',
                    answer: ''
                }],
                [{
                    question: 'Do you have ANY moving violations or accidents in the last 5 years?',
                    answer: ''
                }],
                [{
                    question: 'Health Problems?',
                    answer: ''
                }],
                [{
                    question: 'Location of home?',
                    answer: ''
                }],
                [{
                    question: 'Why leaving previous/current company?',
                    answer: ''
                }],
                [{
                    question: 'Owner operator or company driver?',
                    answer: ''
                }],

                [{
                    question: 'How long can you stay on the road? ',
                    answer: ''
                }],
            ]),
            additionalInformation: [''] 
        })
    }
    ngOnInit(): void {

    }
    onDateChange($event, key) {
        const date = moment($event[key].substring(0, $event[key].length - 1)).format('YYYY-MM-DD')
        this.candidateForm.get(key).setValue(date)
    }
    addQuestion(event,key,index) {
        this.candidateForm.get(key).value[index].answer = event
        console.log(this.candidateForm.value);
        
    }
    save() {
        if(this.candidateForm.get('resource').value==='Other'){
           if(!this.candidateForm.get('otherResource').value){
            this.notify.error('Please enter Other Resource!', 2)
           }
        }
        if(this.candidateForm.value.cdl === 'no'){
            this.candidateForm.get('cdlExp').setValidators([]);
            this.candidateForm.get('cdlExp').updateValueAndValidity();
            this.candidateForm.get('cdlExp').setValue('')
            this.candidateForm.get('autoHaulingExp').setValidators([]);
            this.candidateForm.get('autoHaulingExp').updateValueAndValidity();
            this.candidateForm.get('autoHaulingExp').setValue('')
        }
        if (this.candidateForm.invalid ) {
            const formValidStatus = markControlsAsTouched(this.candidateForm)
            if (!formValidStatus) {
                this.notify.error('Please enter valid form inputs!', 2)
                return
            }
        }
        const value = this.candidateForm.value
        const data = {
            contact: {
                name: value.name,
                emails: [
                    { value: value.email, isUnsubscribed: false }
                ],
                phones: [
                    { value: value.phone, isUnsubscribed: true }
                ],
                address: { 
                    addressLine1: value.addressLine,
                    city: value.city,
                    state: value.state,
                    zip: value.zip
                },
                dob:value.dob
            },
            questions: [
                {
                    question: "Do you have a CDL Class-A?",
                    answer: this.candidateForm.value.cdl
                },
                {
                  question: "How much CDL-A driving experience do you have?",
                  answer: this.candidateForm.value.cdlExp
                },
                {
                  question: "How much auto hauling experience do you have?",
                  answer: this.candidateForm.value.autoHaulingExp
                },
                {
                  question: "How did you hear about us?",
                  answer: this.candidateForm.value.resource === 'Other' ? `Other: ${this.candidateForm.value.otherResource}` : this.candidateForm.value.resource
                },
                ...value.bgInformation,
                {
                    question: "Additional Information",
                    answer: this.candidateForm.value.additionalInformation
                }
            ],
            stage: 'New',
        }

        this.coreStore.dispatch(new fromCoreStore.CreateCandidate({candidate:data, filter: this.filter}))
        this.coreStore.dispatch(new CloseModal())

        // this.coreStore.select(fromCoreStore.selectCandidateFilter).pipe(takeUntil(this.destroyed$)).subscribe(filter => {
        //     this.filter = filter
        // })
        // this.filter.page = 1
        // this.coreStore.dispatch(new fromCoreStore.SetCandidatesFilter({ ...this.filter, stage: null }))

    }
    close() {
        this.coreStore.dispatch(new CloseModal())
    }

    setClass(value) {
        for (let v of this.cdl) {
            if (v._id === value) {
                let text = v.value.toLowerCase()
                this.candidateForm.get('cdl').setValue(text)
            }
        }
    }

    setDriving(value) {
        for (let v of this.cdlA) {
            if (v._id === value) {
                let text = v.value.toLowerCase()
                this.candidateForm.get('cdlExp').setValue(text)
            }
        }
    }

    setHaulingExp(value) {
        for (let v of this.autoHaulingExp) {
            if (v._id === value) {
                let text = v.value.toLowerCase()
                this.candidateForm.get('autoHaulingExp').setValue(text)
            }
        }
    }
    
    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
