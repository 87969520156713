import { Component, OnDestroy, OnInit } from '@angular/core'

import { Observable, zip } from 'rxjs'
import { Subject } from 'rxjs/internal/Subject'
import { debounceTime, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { Store } from '@ngrx/store'


import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'

import { CloseAllModal, OpenModal } from '@app/modals/store/actions/layout.actions'

import {
    User,
    Candidate
} from '@app/shared/models'
import { ActivatedRoute } from '@angular/router'
import { GetThreadLiveSuccess } from '@app/core/store'
import { selectAllUsers } from '@app/sidebar/store/selectors/user.selectors'


@Component({
    selector: 'app-candidate',
    template: `
    <div
    >
      <ng-container>
        <app-candidate-details
          *ngIf="candidate"
          [candidate]="candidate"
          [currentUser]="currentUser$ | async"
          [users]="users$ | async"
          (onUpdate)="update($event)"
        ></app-candidate-details>
      </ng-container>
    </div>
  `,
    standalone: false
})
export class CandidateItemComponent implements OnInit, OnDestroy { 
    candidate: Candidate
    currentUser$: Observable<User>
    users$: Observable<User[]>
    destroyed$ = new Subject<boolean>()
    constructor(private route: ActivatedRoute,private store: Store<fromCoreStore.State>) { }
    ngOnInit(): void {

      this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
        const url = this.route.snapshot.url
        this.store.dispatch(new fromCoreStore.GetCandidate({ id: url[1].path}))
      });

      this.users$ = this.store.select(selectAllUsers)
      this.currentUser$ = this.store.select(fromUserStore.getUser)
        this.store
            .select(fromCoreStore.selectCurrentCandidate)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(candidate => (
                this.candidate = candidate))
  }
  update(event: Candidate) {
    this.store.dispatch(
      new fromCoreStore.UpdateCandidate({
        item: { id: event._id, changes: event },
        dispatch: true
      })
    )
  }
    
    ngOnDestroy() {
        this.destroyed$.complete()
    }
}